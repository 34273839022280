import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Cookies from 'universal-cookie';
import { Container, Row, Col, Panel, Form, Option, Select, Input, Button } from 'muicss/react';

import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();

const defaultState = {
  payer: cookies.get('payer') ? cookies.get('payer') : 'Nadine',
  category: "Lebensmittel",
  amount: '',
  paymentDate: new Date()
};

class AddExpenseForm extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState;

    this.handlePayerChange = this.handlePayerChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePayerChange(event) {
    this.setState({payer: event.target.value});
    cookies.set('payer', event.target.value, { path: '/' });
  }

  handleCategoryChange(event) {
    this.setState({category: event.target.value});
  }

  handleAmountChange(event) {
    this.setState({
      amount: event.target.value
    });
  }

  handleDateChange(date) {
    this.setState({
      paymentDate: date
    });
  }

  handleSubmit(event) {
    event.preventDefault()
    var payload = {
        payer: this.state.payer,
        category: this.state.category,
        amount: this.state.amount,
        paymentDate: this.state.paymentDate
    }
    this.props.onAddExpense(payload)
    this.setState(defaultState)
  }


  render() {
    return (
      <Panel>
        <Container fluid={true}>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col className="mui--text-center">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={this.state.paymentDate}
                  onChange={this.handleDateChange}
                  maxDate={new Date()}
                  inline
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  placeholder="Ausgabe in €"
                  type="number"
                  name="amount"
                  onChange={this.handleAmountChange}
                  value={this.state.amount} />
              </Col>
              <Col>
                <Select
                  defaultValue={this.state.category}
                  onChange={this.handleCategoryChange}>
                    <Option value="Lebensmittel" label="Lebensmittel" />
                    <Option value="Drogerie" label="Drogerie"/>
                    <Option value="Tanken" label="Tanken" />
                    <Option value="Technik" label="Technik"/>
                    <Option value="Hausputz" label="Hausputz" />
                    <Option value="Sonstiges" label="Sonstiges"/>
                </Select>
              </Col>
          </Row>
          <Row>
            <Col>
              <Select
                defaultValue={this.state.payer}
                onChange={this.handlePayerChange}>
                  <Option value="Nadine" label="Nadine" />
                  <Option value="Georg" label="Georg" />
              </Select>
            </Col>
          </Row>
          <Row>
            <Col className="mui--text-center">
              <Button variant="raised" color="primary" size="large">Speichern</Button>
            </Col>
          </Row>
        </Form>
        </Container>
      </Panel>
    );
  }
}
export default AddExpenseForm;
