import React, { Component } from 'react';
import BalanceInfo from './BalanceInfo';
import AddExpenseForm from './AddExpenseForm';
import ExpenseList from './ExpenseList';
import { Panel } from 'muicss/react';

import './App.css';

const HOST = process.env.NODE_ENV === 'production' ? '' : 'http://192.168.178.34:3003';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expenses: [],
      balance: 'Keine Einträge vorhanden'
    }

    this.handleAddExpense = this.handleAddExpense.bind(this)
    this.handleDeleteExpense = this.handleDeleteExpense.bind(this)
  }

  handleAddExpense(payload){
    let self = this;
    fetch(HOST + '/expense/new',
    {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {"Content-Type": "application/json"}
    })
    .then(function(res){ self.fetchExpenses(); })
    .catch(function(err){ console.log(err) });
  }

  handleDeleteExpense(id) {
    var self = this;
    fetch(HOST + '/expense/delete',
    {
        method: 'DELETE',
        body: JSON.stringify( {'id':id} ),
        headers: {"Content-Type": "application/json"}
    })
    .then(function(res){ console.log(res); self.fetchExpenses(); })
    .catch(function(err){ console.log(err) });
  }

  fetchExpenses() {
    var self = this;
    fetch(HOST + '/expense/list')
          .then(response => response.json())
          .then(function(data) {
            self.setState({ expenses: data }, function() {
              this.calculateBalance();
            })
          });
  }

  calculateBalance() {
    let { expenses } = this.state;
    let sum, half, max, diff = 0;
    let debtor = 'Georg';
    let debtee = 'Nadine';
    let totals = { 'Nadine': 0, 'Georg': 0 };

    if(expenses.length<1) {
      this.setState({ balance: 'Keine Einträge vorhanden' });
      return true;
    }

    expenses.forEach(e => {
      if(e.payer === 'Nadine') totals['Nadine'] = totals['Nadine'] + e.amount;
      else totals['Georg'] = totals['Georg'] + e.amount;
    });

    if(totals['Nadine'] === totals['Georg']) return 0;

    sum = totals['Nadine'] + totals['Georg'];
    half = sum / 2;
    max = Math.max(totals['Nadine'], totals['Georg']);

    diff = max - half;
    diff = diff.toFixed(2).replace('.',',')

    if(max === totals['Georg']) {
      debtor = 'Nadine';
      debtee = 'Georg';
    }

    const balance = debtor + ' > ' + debtee + ': ' + diff + '€';
    this.setState({ balance });

  }

  componentDidMount() {
    this.fetchExpenses()
  }

  render() {
     const { balance, expenses } = this.state;
    return (
      <div className="App">
        <Panel className="mui--text-headline mui--text-center">Ausgaben</Panel>
        <AddExpenseForm onAddExpense={this.handleAddExpense} />
        <BalanceInfo balance={balance}/>
        <ExpenseList
          expenses={expenses}
          onDeleteExpense={this.handleDeleteExpense} />
      </div>
    );
  }
}

export default App;
