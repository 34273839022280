import React, { Component } from 'react';
import { Panel } from 'muicss/react';


class BalanceInfo extends Component {
  render() {
    const balance = this.props.balance
    return (
      <Panel>
        <div className="mui--text-title mui--text-center">{balance}</div>
      </Panel>
    );
  }
}
export default BalanceInfo;
