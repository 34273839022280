import React, { Component } from 'react';
import moment from 'moment'
import { Panel, Container, Row, Col, Button } from 'muicss/react';

const dateFormat = "dd, DD.MM."

class ExpenseList extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false };
    this.handleDeleteExpense = this.handleDeleteExpense.bind(this)
  }

  handleDeleteExpense(id,e) {
    this.setState({ loading: true })
    this.props.onDeleteExpense(id)
  }

  render() {
    const expenses = this.props.expenses;
    return (
      <Panel>
        <Container fluid={true}>
          {expenses.slice(0,10).map(e =>
            <Row key={e.id} style={{height: '50px'}}>
              <Col xs="3">{moment(e.paymentDate).format(dateFormat)}</Col>
              <Col xs="2" className="mui--text-right">{e.amount} €</Col>
              <Col xs="3">{e.category}</Col>
              <Col xs="2">{e.payer}</Col>
              <Col xs="1">
                <a className="mui--text-danger" style={{ borderRadius: '3px', textDecoration: 'none', padding: '.2em .5em', background: 'red', color: 'white' }} href="#" onClick={(event) => this.handleDeleteExpense(e.id, event)}>x</a>
              </Col>
            </Row>
          )}
        </Container>
      </Panel>
    );
  }
}
export default ExpenseList;
